import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ roles }) => {
  const { user } = useSelector((state) => state.user);
  const { isAuthenticated, role } = user;

  // Redirect to login page if not authenticated
  if (!isAuthenticated) {
    return (
      <Navigate
        to={
          roles.includes("admin")
            ? "/admin/login"
            : roles.includes("user")
            ? "/login"
            : "/vendor/login"
        }
        replace
      />
    );
  }

  if (!roles.includes(role)) {
    if (role === "user") {
      return <Navigate to="/" replace />;
    } else if (role === "admin") {
      return <Navigate to="/admin/dashboard" replace />;
    } else if (role === "vendor") {
      return <Navigate to="/vendor/dashboard" replace />;
    }
  }

  return <Outlet />;
};

export const PublicRoute = () => {
  const { user } = useSelector((state) => state.user);
  const { isAuthenticated, role } = user;
  const { state } = useLocation();
  if (isAuthenticated) {
    if (role === "user") {
      return <Navigate to={state ? state : "/"} replace />;
    } else if (role === "admin") {
      return <Navigate to="/admin/dashboard" replace />;
    } else if (role === "vendor") {
      return <Navigate to="/vendor/dashboard" replace />;
    }
  }

  return <Outlet />;
};
