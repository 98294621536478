import { lazy } from "react";
import App from "../App";
import { ProtectedRoute, PublicRoute } from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import ErrorFallback from "../components/Error/ErrorFallback";

const Register = lazy(() => import("../pages/VendorPages/Auth/Register"));
const Login = lazy(() => import("../pages/VendorPages/Auth/Login"));
const Advertisements = lazy(() =>
  import("../pages/VendorPages/Advertisements")
);
const VendorLayout = lazy(() => import("../pages/Layout/AdminLayout"));
const Dashboard = lazy(() => import("../pages/VendorPages/Dashboard"));
const AddorEditProduct = lazy(() =>
  import("../components/AdminComponents/Products/AddorEditProduct")
);
const OrderDetail = lazy(() =>
  import("../components/AdminComponents/Orders/OrderDetails")
);
const Reviews = lazy(() => import("../pages/VendorPages/Reviews"));
const Products = lazy(() => import("../pages/VendorPages/Product"));
const Orders = lazy(() => import("../pages/VendorPages/Orders"));
const RFQManagement = lazy(() => import("../pages/VendorPages/RFQ"));
const Account = lazy(() => import("../pages/VendorPages/Account"));

const venderRoutes = {
  path: "/vendor/",
  element: <App />,
  errorElement: <ErrorFallback />,
  children: [
    {
      path: "",
      element: <ProtectedRoute roles={["vendor"]} />,
      children: [
        {
          path: "",
          element: <VendorLayout />,
          children: [
            { path: "", element: <Navigate to="/vendor/dashboard" replace /> },
            { path: "dashboard", element: <Dashboard /> },
            { path: "account", element: <Account /> },
            { path: "orders", element: <Orders /> },
            {
              path: "orders/:orderId",
              element: <OrderDetail isAdmin={false} />,
            },
            { path: "products", element: <Products /> },
            {
              path: "product/add",
              element: <AddorEditProduct />,
            },
            {
              path: "product/edit/:productId",
              element: <AddorEditProduct />,
            },
            {
              path: "reviews/:productId",
              element: <Reviews />,
            },
            {
              path: "advertisements",
              element: <Advertisements />,
            },
            {
              path: "rfq-list",
              element: <RFQManagement />,
            },
          ],
        },
      ],
    },

    {
      path: "",
      element: <PublicRoute />,
      children: [
        { path: "register", element: <Register /> },
        { path: "login", element: <Login /> },
      ],
    },
  ],
};

export default venderRoutes;
