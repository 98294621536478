import {
  CheckCircle,
  Container,
  Hourglass,
  Package,
  Truck,
  XCircle,
} from "lucide-react";
import {
  MdHome,
  MdImage,
  MdShoppingCart,
  MdCategory,
  MdPeople,
  MdRocketLaunch,
} from "react-icons/md";
import { IoStorefrontSharp } from "react-icons/io5";
import { FaClipboardCheck } from "react-icons/fa6";
import { RiRefund2Line } from "react-icons/ri";
import { Icon } from "@chakra-ui/react";
import env from "../utils/config/env";

export const categoryRegex = /^[A-Z][a-zA-Z0-9\s'’-]*$/;
export const nameRegex = /^[A-Z][a-z]+(?: [a-zA-Z]+)*$/;
export const phoneRegex = /^[0-9]{10}$/;
export const sixDigitRegex = /^[0-9]{6}$/;

export const USER_URL = "/user/e-com";
export const ADMIN_URL = "/admin/e-com";
export const VENDOR_URL = "/vendor";
export const SHIPPING_CHARGE = 5;
export const GST_IN_PERCENTAGE = 18;
export const MIN_TEXT_AREA_HEIGHT = 32;
export const PAYPAL_INITIAL_OPTIONS = {
  clientId: env.PAYPAL_CLIENT_ID,
  currency: "USD",
  intent: "capture",
};
export const darkColor = "#0b1437";
export const darkCardBg = "#111c44";
export const allowedImageFileTypes = ["image/jpeg", "image/png", "image/webp"];
export const hostname =
  typeof window !== "undefined" ? window.location.hostname : "localhost.com";
export const productHeaders = [
  "Photo",
  "Product Name",
  "Price",
  "Stock",
  "Created At",
  "List/Unlist",
  "Actions",
  "Offer",
  "Reviews",
];
export const adminProductHeaders = [
  "Photo",
  "Product Name",
  "Price",
  "Stock",
  "Created At",
  "Actions",
  "Offer",
];

export const categoryHeaders = [
  "S.No",
  "Category Name",
  "Created At",
  "List/Unlist",
  "Subcategory",
  "Actions",
  "Offers",
];

export const offerTableHeaders = [
  "S.No",
  "Offer Name",
  "Code",
  "Discount",
  "Valid From",
  "Valid Until",
  "List/Unlist",
  "Actions",
];
export const bannerTableHeaders = [
  "S.No",
  "Image",
  "Title",
  // "Start Date",
  // "End Date",
  // "List/Unlist",
  "Actions",
];
export const userTableHeaders = [
  "S.No",
  "Name",
  "Email",
  "Is Verified",
  "Joined Date",
  "Actions",
];
export const vendorTableHeaders = [
  "S.No",
  "Name",
  "Email",
  "Phone Number",
  "Registration Date",
  "Email Verified",
  "Actions",
];

export const advertisementHeader = [
  "S.No",
  "Vendor Name",
  "Address",
  "Contact",
  "Date Applied",
  "Actions",
];

export const orderStatusIcons = {
  initiated: <Package className="size-5 text-blue-500" />,
  shipped: <Container className="size-5 text-purple-500" />,
  outForDelivery: <Truck className="size-5 text-yellow-500" />,
  delivered: <CheckCircle className="size-5 text-green-500" />,
  cancelled: <XCircle className="size-5 text-red-500" />,
  return: <RiRefund2Line className="size-5 text-green-500" />,
  pending: <Hourglass className="size-5 text-yellow-500" />,
};

export const orderStatusLabels = {
  pending: "Pending",
  initiated: "Order Placed",
  shipped: "Shipped",
  outForDelivery: "Out for Delivery",
  delivered: "Delivered",
  cancelled: "Order Cancelled",
  return: "Returned",
};

export const validTransitions = {
  pending: ["shipped", "cancelled"],
  initiated: ["shipped", "cancelled"],
  shipped: ["outForDelivery", "cancelled"],
  outForDelivery: ["delivered", "cancelled"],
  delivered: [],
  cancelled: [],
  return: [],
};

export const orderStatusFilter = [
  { value: "", name: "All" },
  { value: "pending", name: "Pending" },
  { value: "initiated", name: "Initiated" },
  { value: "shipped", name: "Shipped" },
  { value: "outForDelivery", name: "Out For Delivery" },
  { value: "delivered", name: "Delivered" },
  { value: "cancelled", name: "Cancelled" },
];

export const stepProgressItems = ["Account", "Business", "Verification"];

//   {
//     path: "/admin/dashboard",
//     name: "Dashboard",
//     icon: <Icon as={MdHome} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/orders",
//     name: "Orders",
//     icon: (
//       <Icon as={MdShoppingCart} width="22px" height="22px" color="inherit" />
//     ),
//   },
//   {
//     path: "/admin/products",
//     name: "Products",
//     icon: <Icon as={MdCategory} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/categories",
//     name: "Categories",
//     icon: <Icon as={MdTag} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/offers",
//     name: "Offers",
//     icon: <Icon as={MdLocalOffer} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/users",
//     name: "Users",
//     icon: <Icon as={MdPeople} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/banners",
//     name: "Banners",
//     icon: <Icon as={MdImage} width="22px" height="22px" color="inherit" />,
//   },
//   {
//     path: "/admin/vendor-management",
//     name: "Vendor Management",
//     icon: (
//       <Icon as={IoStorefrontSharp} width="22px" height="22px" color="inherit" />
//     ),
//   },
//   {
//     path: "/admin/new-vendors",
//     name: "New Vendors",
//     icon: <Icon as={MdFiberNew} width="22px" height="22px" color="inherit" />,
//   },
// ];
export const adminSidebarRoutes = [
  {
    name: "Dashboard",
    path: "/admin/dashboard",
    icon: <Icon as={MdHome} width="22px" height="22px" color="inherit" />,
  },
  // {
  //   name: "Orders",
  //   path: "/admin/orders",
  //   icon: (
  //     <Icon as={MdShoppingCart} width="22px" height="22px" color="inherit" />
  //   ),
  // },
  {
    name: "Products",
    icon: <Icon as={MdCategory} width="22px" height="22px" color="inherit" />,
    children: [
      {
        name: "All Products",
        path: "/admin/products",
        // icon: (
        //   <Icon as={MdCategory} width="22px" height="22px" color="inherit" />
        // ),
      },
      {
        name: "Categories",
        path: "/admin/categories",
        // icon: <Icon as={MdTag} width="22px" height="22px" color="inherit" />,
      },
      {
        name: "Offers",
        path: "/admin/offers",
        // icon: (
        //   <Icon as={MdLocalOffer} width="22px" height="22px" color="inherit" />
        // ),
      },
    ],
  },
  {
    name: "Users",
    path: "/admin/users",
    icon: <Icon as={MdPeople} width="22px" height="22px" color="inherit" />,
  },
  {
    name: "Banners",
    path: "/admin/banners",
    icon: <Icon as={MdImage} width="22px" height="22px" color="inherit" />,
  },
  {
    name: "Vendors",
    icon: (
      <Icon as={IoStorefrontSharp} width="22px" height="22px" color="inherit" />
    ),
    children: [
      {
        path: "/admin/vendor-management",
        name: "Manage Vendors",
      },
      {
        name: "New Vendors",
        path: "/admin/new-vendors",
        // icon: (
        //   <Icon as={MdFiberNew} width="22px" height="22px" color="inherit" />
        // ),
      },
    ],
  },
  {
    path: "/admin/advertisements",
    name: "Advertisements",
    icon: (
      <Icon as={MdRocketLaunch} width="22px" height="22px" color="inherit" />
    ),
  },
];

export const VendorSidebarRoutes = [
  {
    path: "/vendor/dashboard",
    name: "Dashboard",
    icon: <Icon as={MdHome} width="22px" height="22px" color="inherit" />,
  },
  {
    path: "/vendor/orders",
    name: "Orders",
    icon: (
      <Icon as={MdShoppingCart} width="22px" height="22px" color="inherit" />
    ),
  },
  {
    path: "/vendor/products",
    name: "Products",
    icon: <Icon as={MdCategory} width="22px" height="22px" color="inherit" />,
  },
  {
    path: "/vendor/advertisements",
    name: "Advertisements",
    icon: (
      <Icon as={MdRocketLaunch} width="22px" height="22px" color="inherit" />
    ),
  },
  {
    path: "/vendor/rfq-list",
    name: "Requested Quotes",
    icon: (
      <Icon as={FaClipboardCheck} width="22px" height="22px" color="inherit" />
    ),
  },
];
