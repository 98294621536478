import { lazy } from "react";
import App from "../App";
import { ProtectedRoute, PublicRoute } from "./ProtectedRoute";
import ErrorFallback from "../components/Error/ErrorFallback";
import Homepage from "../pages/UserPages/Home";
import ProductPage from "../pages/UserPages/Product";
import UserLayout from "../pages/Layout/UserLayout";
// Lazy imports
const LoginPage = lazy(() => import("../pages/UserPages/Login/index"));
const OTPPage = lazy(() => import("../pages/UserPages/OTP"));
const ShopPage = lazy(() => import("../pages/UserPages/Shop"));
const CheckoutPage = lazy(() => import("../pages/UserPages/CheckOut"));
const CartPage = lazy(() => import("../pages/UserPages/Cart"));
const ProfilePage = lazy(() => import("../pages/UserPages/Profile"));
const MyQuotes = lazy(() => import("../pages/UserPages/Profile/MyQuotes"));
const WishlistPage = lazy(() => import("../pages/UserPages/Wishlist"));
const OrdersPage = lazy(() => import("../pages/UserPages/Orders"));
const AddressPage = lazy(() => import("../pages/UserPages/Addresses"));
const RegisterPage = lazy(() => import("../pages/UserPages/Register"));
const OrderDetailPage = lazy(() =>
  import("../pages/UserPages/Orders/OrderDetailPage")
);
const Payment = lazy(() => import("../pages/UserPages/Payment"));
const ForgotPassword = lazy(() => import("../pages/UserPages/ForgotPassword"));
const SetNewPassword = lazy(() =>
  import("../pages/UserPages/ForgotPassword/SetNewPassword")
);
const StorePage = lazy(() => import("../pages/UserPages/Store"));
const userRoutes = {
  path: "/",
  element: <App />,
  errorElement: <ErrorFallback />,
  children: [
    {
      path: "",
      element: <UserLayout />,
      children: [
        { path: "/", element: <Homepage /> },
        {
          path: "shop",
          element: <ShopPage />,
        },
        {
          path: "product/:productId",
          element: <ProductPage />,
        },
        {
          path: "store/:vendorId",
          element: <StorePage />,
        },
        // user Protected routes
        {
          path: "/",
          element: <ProtectedRoute roles={["user"]} />,
          children: [
            {
              path: "checkout",
              element: <CheckoutPage />,
            },
            {
              path: "cart",
              element: <CartPage />,
            },
            {
              path: "account",
              element: <ProfilePage />,
            },
            {
              path: "wishlist",
              element: <WishlistPage />,
            },
            {
              path: "orders",
              element: <OrdersPage />,
            },
            {
              path: "orders/:orderId",
              element: <OrderDetailPage />,
            },
            {
              path: "address",
              element: <AddressPage />,
            },

            {
              path: "order-confirmation/:orderId",
              element: <Payment />,
            },
            {
              path: "account/my-quotes",
              element: <MyQuotes />,
            },
          ],
        },
      ],
    },
    // User Public routes, can access only without LoggedIn
    {
      path: "/",
      element: <PublicRoute />,
      children: [
        {
          path: "",
          element: <UserLayout />,
          children: [
            {
              path: "login",
              element: <LoginPage />,
            },
            {
              path: "register",
              element: <RegisterPage />,
            },
            {
              path: "otp",
              element: <OTPPage />,
            },
            {
              path: "forgot-password",
              element: <ForgotPassword />,
            },
            {
              path: "reset/:token",
              element: <SetNewPassword />,
            },
          ],
        },
      ],
    },
  ],
};

export default userRoutes;
