import axios from "axios";
import env from "../config/env";
import { setItem } from "../localStorageUtil";
import { useAuthExpirationModal } from "../../context/AuthExpiration";
import { useEffect, useState } from "react";

const axiosInstance = axios.create({
  baseURL: env.BASE_URL, // Your API base URL
  timeout: 5000, // Timeout duration in milliseconds
  withCredentials: true, // Include cookies with requests
});

export const axiosPublicInstance = axios.create({
  baseURL: env.BASE_URL,
});

export const AxiosInterceptor = ({ children }) => {
  const { setModalOpen } = useAuthExpirationModal();
  useEffect(() => {
    const resInterceptor = (response) => {
      const { accessToken, refreshToken } = response?.data;
      if (accessToken) setItem("accessToken", accessToken);
      if (refreshToken) setItem("refreshToken", refreshToken);
      return response;
    };

    const errInterceptor = (error) => {
      const { status } = error;
      if (status === 401) {
        setModalOpen(true);
      }

      return Promise.reject(error);
    };

    const interceptor = axiosInstance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, []);
  return children;
  // return isReady ? children : <></>;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers.refreshToken = `Bearer ${refreshToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => {
//     const { accessToken, refreshToken } = response?.data;
//     if (accessToken) setItem("accessToken", accessToken);
//     if (refreshToken) setItem("refreshToken", refreshToken);
//     return response;
//   },
//   (error) => {
//
//     const { status } = error;
//     if (status === 401 || status === 403) {
//       setModalOpen(true);
//     }

//     return Promise.reject(error);
//   }
// );

// Public instance that doesn't use any tokens

export default axiosInstance;
