import FeedbackCard from "../../AdminComponents/Feedback/FeedbackCard.jsx";
import Pagination from "../Pagination/index.jsx";
import RatingOverview from "./RatingOverview.jsx";

const UserReviews = ({ reviews, rating, className = "" }) => {
  return reviews.length ? (
    <div
      className={`flex flex-col justify-center gap-5 ${className}`}
      id="reviews"
    >
      <h2 className="text-2xl text-gray-800 font-semibold ">
        Reviews & Ratings
      </h2>
      <RatingOverview rating={rating} />
      <div className="grid grid-cols-1  gap-4 mt-2 ">
        {reviews.map((review, index) => (
          <FeedbackCard key={index} {...review} i={index} isUser={true} />
        ))}
      </div>
      {/* <Pagination
        currentPage={1}
        totalCount={3}
        itemsPerPage={2}
        onPageChange={(page) => page + 1}
      /> */}
    </div>
  ) : null;
};

export default UserReviews;
