import React, { useEffect, useMemo, useRef, useState } from "react";
import { Heading } from "../Heading";
import { Slider } from "../Slider";
import ProductCard from "../ProductCard";
import { Configure, Index, useConfigure, useHits } from "react-instantsearch";
import Skeleton from "../Skeleton";
import { useSelector } from "react-redux";
import { getProductsWithWishlistStatus } from "../../../utils/api/products";
import useFetchApi from "../../../Hooks/useFetchApi";
import { USER_URL } from "../../../constants";

const LatestProducts = () => {
  const [sliderState, setSliderState] = useState(0);
  const [newArrivals, setNewArrivals] = useState([]);
  const sliderRef = useRef(null);
  // useConfigure({
  //   facetsRefinements: {
  //     isActive: ["false"],
  //   },
  //   hitsPerPage: 6,
  // });
  // const { results } = useHits();
  const { user } = useSelector((state) => state.user);
  const { data } = useFetchApi({
    url: USER_URL + "/products?createdAt=descending?isActive=true",
    limit: 5,
  });

  useEffect(() => {
    async function updateProductWithwishlist() {
      try {
        // updates the products list with wishlist status when user is logged in
        if (user.isAuthenticated && user.role === "user") {
          const updatedProductList = await getProductsWithWishlistStatus(data);
          setNewArrivals(updatedProductList);
          return;
        }
        setNewArrivals(data);
      } catch (error) {}
    }
    updateProductWithwishlist();
  }, [data, user]);
  return (
    <div className="container-xs mt-10 flex flex-col items-start gap-[29px] md:p-5">
      <Heading size="lg" as="h1" className="!font-sans">
        <span className="text-gray-900">Latest.&nbsp;</span>
        <span className="text-blue_gray_700_cc">
          Check out the newest arrivals just for you
        </span>
      </Heading>
      {!newArrivals.length ? (
        <div className="grid grid-cols-5 md:grid-cols-3 sm:grid-cols-1 gap-6 mx-2.5">
          {Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} />
          ))}
        </div>
      ) : null}
      <Index indexName="latest_products" indexId="latest_product">
        <Configure hitsPerPage={6} facetsRefinements={{ isB2B: ["true"] }} />
        <div className="flex flex-col items-center gap-16 self-stretch sm:gap-8">
          <div className="mx-2.5 flex w-full max-w-[1296px] gap-5 self-stretch md:mx-0 md:flex-col">
            <Slider
              autoPlay
              autoPlayInterval={3000}
              responsive={{
                0: { items: 1 },
                550: { items: 2 },
                1050: { items: 4 },
              }}
              renderDotsItem={(props) => {
                return props?.isActive ? (
                  <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_cc" />
                ) : (
                  <div className="mr-1.5 h-[6px] w-[6px] rounded-[3px] bg-gray_900_66" />
                );
              }}
              activeIndex={sliderState}
              onSlideChanged={(e) => {
                setSliderState(e?.item);
              }}
              ref={sliderRef}
              items={newArrivals.map((item) => (
                <ProductCard {...item} key={item._id} _id={item.id} {...item} />
              ))}
            />
          </div>
        </div>
      </Index>
    </div>
  );
};

export default LatestProducts;
