import { lazy } from "react";
import App from "../App";
import { ProtectedRoute, PublicRoute } from "./ProtectedRoute";
import { Navigate } from "react-router-dom";
import ErrorFallback from "../components/Error/ErrorFallback";

const AdminLoginPage = lazy(() => import("../pages/AdminPages/Login"));
const Category = lazy(() => import("../pages/AdminPages/category"));
const Offers = lazy(() => import("../pages/AdminPages/Offer"));
const Orders = lazy(() => import("../pages/AdminPages/Orders"));
const Products = lazy(() => import("../pages/AdminPages/Products"));
const BannerManagement = lazy(() => import("../pages/AdminPages/Banners"));
const OrderDetailPage = lazy(() =>
  import("../pages/AdminPages/Orders/OrderDetailPage")
);
const Feedback = lazy(() => import("../pages/AdminPages/Feedback"));
const Users = lazy(() => import("../pages/AdminPages/users"));
const Dashboard = lazy(() => import("../pages/AdminPages/Dashboard"));
const AdminLayout = lazy(() => import("../pages/Layout/AdminLayout"));
const Vendors = lazy(() => import("../pages/AdminPages/Vendor/Vendors"));
const NewVendors = lazy(() => import("../pages/AdminPages/Vendor/NewVendors"));
const ViewProductForApproval = lazy(() =>
  import("../pages/AdminPages/Products/ViewProductForApproval")
);
const Advertisements = lazy(() => import("../pages/AdminPages/Advertisements"));

const adminRoutes = {
  path: "/admin/",
  element: <App isAdmin={true} />,
  errorElement: <ErrorFallback />,
  children: [
    // Admin Protected routes
    {
      path: "",
      element: <ProtectedRoute roles={["admin"]} />,
      children: [
        {
          path: "",
          element: <AdminLayout />,
          children: [
            { path: "", element: <Navigate to="/admin/dashboard" /> },
            { path: "dashboard", element: <Dashboard /> },
            { path: "banners", element: <BannerManagement /> },
            // { path: "orders", element: <Orders /> },
            // { path: "orders/:orderId", element: <OrderDetailPage /> },
            { path: "products", element: <Products /> },
            {
              path: "product/approve/:productId",
              element: <ViewProductForApproval />,
            },
            { path: "reviews/:productId", element: <Feedback /> },
            { path: "categories", element: <Category /> },
            { path: "offers", element: <Offers /> },
            { path: "users", element: <Users /> },
            {
              path: "vendor-management",
              element: <Vendors />,
            },
            {
              path: "new-vendors",
              element: <NewVendors />,
            },
            {
              path: "advertisements",
              element: <Advertisements />,
            },
          ],
        },
      ],
    },
    // Admin Public routes
    {
      path: "",
      element: <PublicRoute />,
      children: [{ path: "login", element: <AdminLoginPage /> }],
    },
  ],
};

export default adminRoutes;
