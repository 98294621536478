import React from "react";
import Header from "../../../components/UserComponents/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../../components/UserComponents/Footer";

const UserLayout = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Header
        className={`${
          pathname.includes("store") ? "static" : "sticky top-0 z-[49]"
        }`}
      />
      <main>
        <Outlet />
      </main>
      <Footer className="self-stretch" />
    </>
  );
};

export default UserLayout;
